import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import oFetch from 'o-fetch';
import classNames from 'classnames';
import utils from '@/lib/utils';
import safeMoment from '@/lib/safe-moment';
import moment from 'moment';
import { Tooltip } from 'react-tippy';
import { appRoutes } from '@/lib/legacy-routes';
import { RotaWeek } from '@/lib/rota-date';
import format from 'date-fns/format';
import { SHORT_TIME_FORMAT, COMMON_DATE_FORMAT } from '@/lib/date-fns-formats';
import { minutesToFormattedHoursAndMinutes } from '@/lib/format-funcs';
import { FaMoneyBillTrendUp } from "react-icons/fa6";

function renderOwedHoursTooltipContent(args) {
  const owedHours = oFetch(args, 'owedHours');
  if (oFetch(owedHours, 'length') === 0) {
    return null;
  }

  return (
    <ul>
      <h3>Owed Hours</h3>

      {owedHours.map((owedHour) => {
        const sOwedHourStartsAt = oFetch(owedHour, 'startsAt');
        const dOwedHourStartsAt = sOwedHourStartsAt ? safeMoment.iso8601Parse(sOwedHourStartsAt).toDate() : null;
        const formattedStartsAt = dOwedHourStartsAt ? format(dOwedHourStartsAt, SHORT_TIME_FORMAT) : '';
        const sOwedHourEndsAt = oFetch(owedHour, 'endsAt');
        const dOwedHourEndsAt = sOwedHourEndsAt ? safeMoment.iso8601Parse(sOwedHourEndsAt).toDate() : null;
        const formattedEndsAt = dOwedHourEndsAt ? format(dOwedHourEndsAt, SHORT_TIME_FORMAT) : '';
        const dPaySlipDate = safeMoment.uiDateParse(oFetch(owedHour, 'payslipDate')).toDate();
        const payslipRotaWeek = RotaWeek.fromDate({
          dCalendarDate: dPaySlipDate,
        });
        const dPayslipWeekStartDate = payslipRotaWeek.startDate();
        const dPayslipWeekEndDate = payslipRotaWeek.endDate();
        const sOwedHourDate = oFetch(owedHour, 'date');
        const dOwedHourDate = sOwedHourDate ? safeMoment.uiDateParse(sOwedHourDate).toDate() : null;

        const owedHourHasDates = dOwedHourDate !== null;
        const owedHourWeek = owedHourHasDates ? RotaWeek.fromDate({ dCalendarDate: dOwedHourDate }) : null;
        const mOwedHourWeekStartDate = owedHourHasDates ? moment(owedHourWeek.startDate()) : null;
        const mOwedHourWeekEndDate = owedHourHasDates ? moment(owedHourWeek.endDate()) : null;

        const mPayslipWeekStartDate = moment(dPayslipWeekStartDate);
        const mPayslipWeekEndDate = moment(dPayslipWeekEndDate);

        return (
          <li
            key={oFetch(owedHour, 'id')}
            style={{ listStyleType: 'none' }}
          >
            <div style={{ textAlign: 'left' }}>
              <span>
                { args.showDates ? <b>{`${dOwedHourDate ? format(dOwedHourDate, COMMON_DATE_FORMAT) : ''} `}</b> : null}
                { args.showDates ? null : <span><FaMoneyBillTrendUp style={{ display: 'inline' }} /> </span> }
                { `${minutesToFormattedHoursAndMinutes(oFetch(owedHour, 'minutes'))} ` }
              </span>
              { owedHourHasDates && (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={appRoutes.staffMemberOwedHours({
                      staffMemberId: oFetch(args, 'staffMemberId'),
                      mStartDate: mOwedHourWeekStartDate,
                      mEndDate: mOwedHourWeekEndDate,
                    })}
                  >
                  {formattedStartsAt}-{formattedEndsAt}
                </a>
              )}
              { !owedHourHasDates && (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={appRoutes.staffMemberOwedHours({
                    staffMemberId: oFetch(args, 'staffMemberId'),
                    mPayslipStartDate: mPayslipWeekStartDate,
                    mPayslipEndDate: mPayslipWeekEndDate,
                  })}
                >
                  {formattedStartsAt}-{formattedEndsAt}
                </a>
              )}
            </div>
          </li>
        );
      })}
    </ul>
  );
}

function ReportItem(props) {
  const report = oFetch(props, 'report');

  const staffMemberId = oFetch(props, 'report.staffMemberId');
  const sWeekDates = oFetch(props, 'weekDates');
  const sStartDate = oFetch(props, 'startDate');
  const mStartDate = safeMoment.uiDateParse(sStartDate);
  const dStartDate = mStartDate.toDate();
  const mEndDate = safeMoment.uiDateParse(oFetch(props, 'endDate'));
  const reportPaymentData = oFetch(props, 'reportPaymentData');
  const paymentWeekData = oFetch(props, "paymentWeekData");
  const paymentWeekPublished = oFetch(paymentWeekData, 'paymentWeekPublished');

  const financeReportWeek  = RotaWeek.fromDate({
    dCalendarDate: dStartDate,
  });
  const mondayOwedHours = oFetch(report, 'mondayOwedHours');
  const tuesdayOwedHours = oFetch(report, 'tuesdayOwedHours');
  const wednesdayOwedHours = oFetch(report, 'wednesdayOwedHours');
  const thursdayOwedHours = oFetch(report, 'thursdayOwedHours');
  const fridayOwedHours = oFetch(report, 'fridayOwedHours');
  const saturdayOwedHours = oFetch(report, 'saturdayOwedHours');
  const sundayOwedHours = oFetch(report, 'sundayOwedHours');
  const outsideWeekOwedHours = oFetch(report, 'outsideWeekOwedHours');
  const allOwedHours = [];
  [
    mondayOwedHours,
    tuesdayOwedHours,
    wednesdayOwedHours,
    thursdayOwedHours,
    fridayOwedHours,
    saturdayOwedHours,
    sundayOwedHours,
    outsideWeekOwedHours,
  ].forEach((groupedOwedHours) => {
    groupedOwedHours.forEach((groupedOwedHour) => {
      allOwedHours.push(groupedOwedHour);
    });
  });

  function renderOwedHoursTooltip(content) {
    if (content == null) {
      return null;
    }

    return (
      <Tooltip
        arrow
        theme="light"
        position="right"
        interactive
        html={content}
      >
        <span className="boss-table__tooltip">
          <span className="boss-tooltip boss-tooltip_role_alert">
            <FaMoneyBillTrendUp />
          </span>
        </span>
      </Tooltip>
    );
  }

  function renderWeekDaysCells() {
    const mondayHoursCount = utils.round(oFetch(report, 'mondayHoursCount'), 2);
    const tuesdayHoursCount = utils.round(oFetch(report, 'tuesdayHoursCount'), 2);
    const wednesdayHoursCount = utils.round(oFetch(report, 'wednesdayHoursCount'), 2);
    const thursdayHoursCount = utils.round(oFetch(report, 'thursdayHoursCount'), 2);
    const fridayHoursCount = utils.round(oFetch(report, 'fridayHoursCount'), 2);
    const saturdayHoursCount = utils.round(oFetch(report, 'saturdayHoursCount'), 2);
    const sundayHoursCount = utils.round(oFetch(report, 'sundayHoursCount'), 2);

    const mondayOwedHours = oFetch(report, 'mondayOwedHours');
    const tuesdayOwedHours = oFetch(report, 'tuesdayOwedHours');
    const wednesdayOwedHours = oFetch(report, 'wednesdayOwedHours');
    const thursdayOwedHours = oFetch(report, 'thursdayOwedHours');
    const fridayOwedHours = oFetch(report, 'fridayOwedHours');
    const saturdayOwedHours = oFetch(report, 'saturdayOwedHours');
    const sundayOwedHours = oFetch(report, 'sundayOwedHours');

    return [
      [mondayHoursCount, mondayOwedHours],
      [tuesdayHoursCount, tuesdayOwedHours],
      [wednesdayHoursCount, wednesdayOwedHours],
      [thursdayHoursCount, thursdayOwedHours],
      [fridayHoursCount, fridayOwedHours],
      [saturdayHoursCount, saturdayOwedHours],
      [sundayHoursCount, sundayOwedHours],
    ].map((values, index) => {
      const [dayHoursCount, dayOwedHours] = values;
      const dayHasOwedHours = oFetch(dayOwedHours, 'length') > 0;
      const sWeekDate = sWeekDates.get(index);
      const dWeekDate = safeMoment.uiDateParse(sWeekDate).toDate();

      const owedHourTooltipContent = renderOwedHoursTooltipContent({
        showDates: false,
        owedHours: dayOwedHours,
        financeReportWeek,
        staffMemberId,
      });

      if (dayHoursCount === 0 && !dayHasOwedHours) {
        return (
          <div key={index} className="boss-table__cell">
            <p className="boss-table__text">-</p>
          </div>
        );
      } else {
        return (
          <div key={index} className="boss-table__cell">
            <p className="boss-table__text">
              <a
                href={appRoutes.staffMemberHoursOverview({ staffMemberId, dDate: dWeekDate })}
                className="boss-table__link"
              >
                { dayHoursCount }
                { dayHasOwedHours && renderOwedHoursTooltip(owedHourTooltipContent) }
              </a>
            </p>
          </div>
        );
      }
    });
  }

  function getNetWagesText(params) {
    const hasNetWages = oFetch(params, 'hasNetWages');
    const paymentWeekPublished = oFetch(params, 'paymentWeekPublished');
    const wagesZero = oFetch(params, 'wagesZero');
    if (!(hasNetWages && paymentWeekPublished) || wagesZero) {
      return null;
    }
    const wagesPending = oFetch(params, 'wagesPending');
    const wagesReceived = oFetch(params, 'wagesReceived');

    if (wagesPending) {
      return 'Pending';
    } else if (wagesReceived) {
      return 'Received';
    } else {
      throw new Error('Impossible condition encountered');
    }
  }

  function getNetWagesIconColorClass(params) {
    let result = null;
    const hasNetWages = oFetch(params, 'hasNetWages');
    const paymentWeekPublished = oFetch(params, 'paymentWeekPublished');
    if (!(hasNetWages && paymentWeekPublished)) {
      return result;
    }

    const wagesPending = oFetch(params, 'wagesPending');
    const wagesReceived = oFetch(params, 'wagesReceived');

    if (wagesPending) {
      result = 'boss-indicator_color_accent-red';
    } else if (wagesReceived) {
      result = 'boss-indicator_color_accent-green';
    }

    return result;
  }

  function getNetWageIconClass(params) {
    let result = null;
    const hasNetWages = oFetch(params, 'hasNetWages');
    const paymentWeekPublished = oFetch(params, 'paymentWeekPublished');
    if (!(hasNetWages && paymentWeekPublished)) {
      return result;
    }

    const wagesPending = oFetch(params, 'wagesPending');
    const wagesReceived = oFetch(params, 'wagesReceived');

    if (wagesPending) {
      result = 'boss-indicator__icon_hour-glass boss-indicator__icon_size_xs';
    } else if (wagesReceived) {
      result = 'boss-indicator__icon_check-bold boss-indicator__icon_size_s';
    }

    return result;
  }

  const fullName = oFetch(report, 'staffMemberName');
  const weeklyHours = utils.round(oFetch(report, 'weeklyHours'), 2);
  const owedHours = utils.round(oFetch(report, 'owedHours'), 2);

  const acessories = utils.round(oFetch(report, 'acessories'), 2);
  const acessoriesColor = utils.colorizedAmount(acessories);
  const totalHoursCount = utils.round(oFetch(report, 'totalHoursCount') + owedHours, 2);
  const paidHolidayDaysCount = oFetch(report, 'paidHolidayDaysCount');
  const sickLeaveDaysCount = oFetch(report, 'sickLeaveDaysCount');
  const payRateType = oFetch(report, 'payRateType');
  const paymentStatus = oFetch(reportPaymentData, 'status');
  const hasNetWages = paymentStatus != null;

  const pendingCalculation = oFetch(report, 'pendingCalculation');
  const staffMemberDisabled = oFetch(report, 'staffMemberDisabled');

  const wagesZero = paymentStatus === 'zero';
  const wagesPending = paymentStatus === 'pending';
  const wagesReceived = paymentStatus === 'received';

  const netWagesText = getNetWagesText({
    hasNetWages,
    paymentWeekPublished,
    wagesReceived,
    wagesPending,
    wagesZero,
  });

  const fullNameCellClassName = classNames({
    'boss-table__cell': true,
    'boss-table__cell_indicator_user-disabled': staffMemberDisabled,
  });

  const rowClassName = classNames({
    'boss-table__row': true,
    'boss-table__row_state_pre-calculated': pendingCalculation,
  });

  const fullNameCellTextClassName = classNames({
    'boss-table__text': true,
    'boss-table__text_indicator_accessory': acessories !== 0,
  });

  const owedHoursClassName = classNames({
    'boss-table__cell': true,
    'boss-table__cell_indicator_clock-warning': owedHours !== 0,
  });
  const paidHolidayDaysCountClassName = classNames({
    'boss-table__cell': true,
    'boss-table__cell_indicator_clock-warning': paidHolidayDaysCount !== 0,
  });
  const netWagesSectionStylesClasses = classNames({
    'boss-table__text_role_important':
      hasNetWages && (!paymentWeekPublished || wagesZero || wagesPending || wagesReceived),
    'boss-table__text_state_alert': hasNetWages && paymentWeekPublished && wagesPending,
    'boss-table__text_state_success': hasNetWages && paymentWeekPublished && wagesReceived,
  });
  const netWagesIconAccentColorClass = getNetWagesIconColorClass({
    hasNetWages,
    paymentWeekPublished,
    wagesPending,
    wagesReceived,
  });
  const netWagesIconClass = getNetWageIconClass({
    hasNetWages,
    paymentWeekPublished,
    wagesPending,
    wagesReceived,
  });

  return (
    <div className={rowClassName}>
      <div className={fullNameCellClassName}>
        <p className={fullNameCellTextClassName}>
          <a
            href={appRoutes.staffMember(staffMemberId)}
            className="boss-table__link"
            style={{ textTransform: 'capitalize' }}
          >
            {fullName}
          </a>
        </p>
      </div>
      <div className="boss-table__cell">
        <p className="boss-table__text" style={{ textTransform: 'capitalize' }}>
          {payRateType}
        </p>
      </div>
      {renderWeekDaysCells()}
      <div className="boss-table__cell">
        <p className="boss-table__text">{weeklyHours}</p>
      </div>
      {owedHours === 0 ? (
        <div className={owedHoursClassName}>
          <p className="boss-table__text">
            {owedHours}
            { oFetch(allOwedHours, 'length') > 0 && (
              renderOwedHoursTooltip(
                renderOwedHoursTooltipContent({
                  owedHours: allOwedHours,
                  showDates: true,
                  staffMemberId,
                  financeReportWeek,
                })
              )
            )}
          </p>
        </div>
      ) : (
        <div className={owedHoursClassName}>
          <a
            href={appRoutes.staffMemberOwedHours({
              staffMemberId: staffMemberId,
              mPayslipStartDate: mStartDate,
              mPayslipEndDate: mEndDate,
            })}
            className="boss-table__link"
          >
            {owedHours}
            { oFetch(allOwedHours, 'length') > 0 && (
              renderOwedHoursTooltip(
                renderOwedHoursTooltipContent({
                  owedHours: allOwedHours,
                  showDates: true,
                  staffMemberId,
                  financeReportWeek,
                })
              )
            )}
          </a>
        </div>
      )}

      <div className="boss-table__cell">
        {acessories === 0 ? (
          <p className="boss-table__text">{utils.moneyFormat(acessories)}</p>
        ) : (
          <a
            href={appRoutes.staffMemberAccessories({ staffMemberId })}
            className="boss-table__text"
            style={{ color: acessoriesColor }}
          >
            {utils.moneyFormat(acessories)}
          </a>
        )}
      </div>
      <div className="boss-table__cell">
        <p className="boss-table__text boss-table__text_role_important">{totalHoursCount}</p>
      </div>
      {paidHolidayDaysCount === 0 && (
        <div className={paidHolidayDaysCountClassName}>
          <p className="boss-table__text">{paidHolidayDaysCount}</p>
        </div>
      )}
      {paidHolidayDaysCount !== 0 && (
        <div className={paidHolidayDaysCountClassName}>
          <a
            href={appRoutes.staffMemberProfileHolidaysTabFromFinanceReport({
              staffMemberId: staffMemberId,
              mPayslipStartDate: mStartDate,
              mPayslipEndDate: mEndDate,
            })}
            className="boss-table__link"
          >
            {paidHolidayDaysCount}
          </a>
        </div>
      )}
      {sickLeaveDaysCount === 0 && (
        <div className="boss-table__cell">
          <p className="boss-table__text">{sickLeaveDaysCount}</p>
        </div>
      )}
      {sickLeaveDaysCount !== 0 && (
        <div className="boss-table__cell">
          <a
            href={appRoutes.staffMemberProfileHolidaysTabFromFinanceReport({
              staffMemberId: staffMemberId,
              mPayslipStartDate: mStartDate,
              mPayslipEndDate: mEndDate,
            })}
            className="boss-table__link"
          >
            {sickLeaveDaysCount}
          </a>
        </div>
      )}
      <div className="boss-table__cell">
        {hasNetWages && (
          <p className={`boss-table__text ${netWagesSectionStylesClasses}`}>
            {paymentWeekPublished && wagesPending && (
              <span className="boss-table__text-indicator">
                <span className={`boss-indicator ${netWagesIconAccentColorClass}`}>
                  <span className={`boss-indicator__icon ${netWagesIconClass}`} />
                </span>
              </span>
            )}
            <span>{netWagesText}</span>
          </p>
        )}
      </div>
    </div>
  );
}

ReportItem.propTypes = {
  report: PropTypes.shape({
    staffMemberId: PropTypes.number.isRequired,
    mondayHoursCount: PropTypes.number.isRequired,
    tuesdayHoursCount: PropTypes.number.isRequired,
    wednesdayHoursCount: PropTypes.number.isRequired,
    thursdayHoursCount: PropTypes.number.isRequired,
    fridayHoursCount: PropTypes.number.isRequired,
    saturdayHoursCount: PropTypes.number.isRequired,
    sundayHoursCount: PropTypes.number.isRequired,
    weeklyHours: PropTypes.number.isRequired,
    owedHours: PropTypes.number.isRequired,
    totalHoursCount: PropTypes.number.isRequired,
    paidHolidayDaysCount: PropTypes.number.isRequired,
    staffMemberName: PropTypes.string.isRequired,
    acessories: PropTypes.number.isRequired,
  }).isRequired,
  reportPaymentData: PropTypes.object.isRequired,
  weekDates: ImmutablePropTypes.list.isRequired,
};

export default ReportItem;
